.lts-banner {
  background-color: #e7ecef;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 1.0);
  z-index: 1000;
}

.lts-img-container {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
}

.lts-banner-img {
    max-width: 150px;
    text-align: center;
    justify-content: center;
}

.lts-nav-div {
    width: 100%;
    text-align: center;
    justify-content: center;
}

.lts-nav-button {
    background-color: #fff;
    color: #3498db; /* Matching color for buttons */
    padding: 10px 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin: 0 10px;
    border: 1px solid #3498db; /* Matching color for border */
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
}

.lts-nav-button-small {
    min-width: 120px;
}

.lts-nav-button-big {
    min-width: 330px;
}

.lts-nav-button-med {
    min-width: 250px;
}

.lts-nav-centered-text {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
}

.lts-nav-button:hover {
  background-color: #2980b9 !important; /* Darker shade on hover */
  color: #fff !important;
}

.lts-banner-link {
  color: #3498db !important; /* Updated color for a professional look */
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: color 0.3s ease;
}
