.limn-form-container {
    width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    margin-top: 30px;
    margin-bottom: 50px;
}

.limn-login-container {
  width: 500px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.limn-column-form {
    display: flex;
    flex-direction: column;
}

.limn-header {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* DIVS */
.limn-div-left-padded-10 {
    width: 80%;
    margin-left: 10%;
}

.limn-div-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.limn-div-right {
    display: flex;
    justify-content: right;
    align-items: right;
}

.limn-div-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.limn-div-row-nowrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.limn-whitespace {
    flex: 1;
    min-width: 200px;
    text-align: center;
}

.limn-div-wrapper {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1000px) {
    .limn-div-wrapper {
        flex-direction: column
    }
    .limn-whitespace {
        display: none
    }
    .limn-form-container {
        width: 100%;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        border-radius: 0px;
    }
}

.limn-width-10 {
    width: 10%;
}
.limn-width-50 {
    width: 50%;
}

.limn-width-40 {
    width: 40%;
}

.limn-width-30 {
    width: 30%;
}

.limn-width-75 {
    width: 75%;
}

.limn-width-80 {
    width: 80%;
}

.limn-width-90 {
    width: 90%;
}

.limn-width-200px {
    width: 200px;
}

.limn-width-300px {
    width: 300px;
}

.limn-margin-25 {
    margin-left: 25%;
}

.limn-margin-10 {
    margin-left: 10%;
}

.limn-margin-right-10 {
    margin-right: 10%;
}

/* Forms */
.limn-form-description {
    padding: 12px;
    width: 50%;
    font-weight: bold;
}

.limn-label-padded {
    margin-bottom: 8px;
    padding: 12px;
    color: #555;
    font-size: 16px;
    white-space: nowrap;
}

.limn-label-nopad {
    color: #555;
    font-size: 16px;
    white-space: nowrap;
}

.limn-select-box {
    flex: 1;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #fff;
    width: 100%;
    min-width: 200px;
    box-sizing: border-box; /* Include padding and border in the total width */
}

.limn-select-box-mini {
    flex: 1;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #fff;
    width: 100%;
    min-width: 100px;
    box-sizing: border-box; /* Include padding and border in the total width */
}

.limn-link {
    color: blue;
}

.limn-green {
    color: green;
}

.limn-input {
  flex: 1;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%; /* Set width to 100% to ensure consistent width for all input elements */
  min-width: 200px;
  box-sizing: border-box; /* Include padding and border in the total width */
}

.limn-input-ro {
  flex: 1;
  padding: 12px;
  margin-bottom: 16px;
  font-size: 16px;
  width: 100%; /* Set width to 100% to ensure consistent width for all input elements */
  min-width: 200px;
}

.limn-file-upload {
  flex: 1;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  min-width: 200px;
  width: 100%; /* Set width to 100% to ensure consistent width for all input elements */
  box-sizing: border-box; /* Include padding and border in the total width */
}

.limn-file-upload:hover {
  background-color: #e9e9e9;
}


/* Paragraph */
.limn-tiny-p {
    max-width: 800px
}

.limn-message {
    padding: 12px;
    width: 50%;
    font-weight: bold;
}

.limn-warning {
    padding: 12px;
    font-weight: bold;
    color: red;
}

/* Button */
.limn-button {
  background-color: #007bff;
  color: #fff;
  padding: 14px;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 5px;
  min-width: 200px;
}

.limn-button-danger {
  background-color: #ff0000;
  color: #000;
  padding: 14px;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 5px;
  min-width: 200px;
}

.limn-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.limn-button-danger:hover {
  background-color: #cc0000;
  color: #000;
}

.limn-button:disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.limn-button-padded-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.limn-padded-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.limn-padded-10p {
    margin-left: 10%;
    margin-right: 10%;
}

.limn-padded-vert-10p {
    margin-top: 10%;
    margin-bottom: 10%;
}

.limn-padded-vert-5p {
    margin-top: 5%;
    margin-bottom: 5%;
}
.limn-padded-top-10px {
    margin-top: 10px;
}
.limn-padded-top-50px {
    margin-top: 50px;
}

.limn-padded-vert-10px {
    margin-top: 10px;
    margin-bottom: 10px;
}

.limn-padded-bottom-10px {
    margin-bottom: 10px;
}

.limn-padded-bottom-50px {
    margin-bottom: 50px;
}

/* FORM SEPARATORS */
.limn-form-separator-100 {
    width: 100%;
    border: none;
    height: 1px;
    background-color: gray;
}

.limn-form-separator-80 {
    width: 80%;
    border: none;
    height: 1px;
    background-color: gray;
}

.limn-clickable {
    cursor: pointer !important;
}

.limn-blue {
    color: blue
}

.limn-bigfont {
    font-size: 18px;
}
